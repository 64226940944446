import * as React from "react"
import Layout from "../components/layout"

export default function NotFound() {
  return (
    <Layout title="404: Page not found">
      404
    </Layout>
  )
}
